import { Injectable } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { StatusEventsService } from '@shared/services/status-events.service';
import { TranslocoService } from '@jsverse/transloco';

@Injectable({
  providedIn: 'root',
})
export class StiiltConfirmDialogService {
  constructor(
    private confirmationService: ConfirmationService,
    private translateService: TranslocoService,
    private statusEventService: StatusEventsService,
  ) {}

  public confirmWithoutReject(header: string, message: string, accept: () => void): void {
    this.openConfirm(header, message, accept, undefined, 'fa-solid fa-triangle-exclamation', false);
  }

  public openConfirm(
    header: string,
    message: string,
    accept: (() => void) | undefined,
    reject: (() => void) | undefined,
    icon = '',
    rejectVisible = true,
  ): void {
    this.confirmationService.confirm({
      key: 'confirmDialog',
      header: header,
      message: message,
      accept: accept,
      reject: (): void => {
        this.statusEventService.setHttpStatus(false);
        if (reject) {
          reject();
        }
      },
      acceptIcon: 'fa-solid fa-check mr-2',
      rejectIcon: 'fa-solid fa-xmark mr-2',
      acceptLabel: rejectVisible
        ? this.translateService.translate('app.yes')
        : this.translateService.translate('app.ok'),
      rejectLabel: this.translateService.translate('app.no'),
      rejectButtonStyleClass: 'p-button-outlined p-button-sm',
      acceptButtonStyleClass: 'p-button-sm',
      rejectVisible: rejectVisible,
      icon: icon,
      closeOnEscape: rejectVisible,
    });
  }
}
